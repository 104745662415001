.shrimp-icon {
  position: relative;
  display: inline-block;

  &__vector {
    display: block;
  }

  &__text {
    font-size: 2.25rem;
    font-family: Arial, sans-serif;
    fill: #333;
    border: 2px solid #666;
  }

  &__eye {
    animation-name: growAndShrinkOnly;
    animation-duration: 1.5s;
    animation-delay: 0.2s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    transform-box: fill-box;
  }

  &__body {
    &--tracer {
      animation-name: trace;
      fill: none;
      stroke-dasharray: 2100;
      stroke-dashoffset: 2100;
      stroke-width: 6px;
      stroke-linecap: butt;
      animation-duration: 2.5s;
      animation-delay: 0.2s;
      animation-direction: alternate-reverse;
      animation-iteration-count: infinite;
    }
  }

  &.swimmer {
    animation: swim-around 5s infinite;
  }

  &.wiggle,
  .wiggle {
    animation: wiggle 0.5s infinite;
  }

  &__antenna {
    transform: rotateX(-18deg);
    animation: wiggle 1.5s infinite;
    transform-origin: 80% 100%;
    transform-box: fill-box;
  }

  &__tail {
    animation-name: trace;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation-duration: 2.5s;
    animation-delay: 0.1s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
  }
}

@keyframes swim-around {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
}

@keyframes wiggle {
  0%,
  100% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(3deg);
  }
}

@keyframes trace {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes growAndShrinkOnly {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.5);
  }
}
