.friendshrimp-feed {
  padding: 1rem;

  .feed__list {
    padding: 0;
    list-style: none;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
  }

  .feed__item {
    background-color: #fdfdfd;
    box-shadow: 3px 2px 8px 5px #ccc;
    padding: 1rem;
    margin-bottom: 3rem;
    list-style: none;

    img {
      max-width: 100%;
      height: auto;
    }

    .item__content {
      max-width: 960px;
      margin: 0 auto;
      text-align: left;
      white-space: pre-wrap;

      p {
        text-indent: 1rem;
      }
    }

    @media all and (min-width: 767px) {
      padding: 1rem 2rem;

      .item__content {
        p {
          text-indent: 2rem;
        }
      }
    }
  }
}
