.sharethis-container {
  width: 80%;
  max-width: 250px;
  position: relative;
  margin: 0 auto;
}

.sharethis__toggle {
  border: none;
  background: rgba(20, 8, 12, 0.5);
  color: white;
  cursor: pointer;
  border-radius: .25rem;
  padding: .5rem 1rem;
  box-shadow: 0px 0px 3px 2px rgba(190,190,190,0.75);
  transition: .5s all;
  animation-name: riseAndSlump;
  animation-duration: 3s;
  animation-delay: 2s;
  animation-fill-mode: backwards;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 0px 1px 1px rgba(90,90,90,0.75);
  }
}

.sharethis__options {
  padding: 0;
}

.sharethis__dropdown {
  background: rgba(250,250,250,.9);
  box-shadow: 0 0 5px #999;
  border-radius: .75rem .75rem 0 0;
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
}

@keyframes riseAndSlump {
  from {
    height: 1.75rem;
  }
  to {
    height: .5rem;
  }
}
