body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  font-size: 16px;
}

html {
  width: 100%;
  height: 100%;
  margin: 0;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #6495ed;
  text-decoration: none;
  transition: all 0.5s;
  padding: 0.25rem;

  &.active {
    color: #111;
  }

  &:hover {
    color: #fa8072;
    text-decoration: none;
    font-weight: bold;
  }
}

a.link {
  text-decoration: underline;
}

p {
  font-weight: 700;
  font-size: 1.2rem;
}

input[type='hidden'] {
  display: none;
}

.small {
  font-size: 0.75rem;
}

.large {
  font-size: 1.5rem;
}

.bold {
  font-weight: bold;
}
