.store {
  &__content {
    padding: 1rem;
  }
}

.fundraising-widget {
  border-radius: 9px;
  border: 9px solid black;
}

.visit-store {
  padding: 0 0.5rem;
  margin-bottom: 1rem;

  .store-link {
    background-color: #ff6a33;
    padding: 0.75rem 1.5rem;
    font-size: 2rem;
    font-weight: bold;
    border-radius: 6px;
    transition: all 0.5s;
    color: white;
    display: inline-block;

    &:hover {
      background-color: #ffa98a;
      color: black;
    }
  }
}
