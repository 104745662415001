.App {
  text-align: center;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.site-title {
  animation-name: shrinkALittle;
  animation-iteration-count: infinite;
  animation-duration: 15s;
  animation-direction: alternate;
  pointer-events: none;
  font-size: 5rem;
  text-transform: uppercase;

  @media screen and (min-width: 768px) {
    font-size: 7rem;
  }

  @media screen and (min-width: 1024px) {
    font-size: 10rem;
  }
}

.page-title {
  font-size: 3rem;
}

.landing-title .subtitle {
  font-size: 3rem;
}

@mixin cool-box-shadow {
  box-shadow: 3px 3px 3px rgba(69,69,69, .5);
}

.cool-container {
  @include cool-box-shadow;

  padding: 1rem;
  width: 1200px;
  max-width: calc(100% - 4rem);
  margin: 0 auto;
}

@keyframes shrinkALittle {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(.5);
  }
}
