.friendshrimp-photo-maker--input {
  opacity: 0;
}

.friendshrimp-photo-maker--upload-label {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  background-color: white;
  color: orange;
  cursor: pointer;
  box-shadow: 4px 1px 4px 4px orangered;
  border-radius: 4px;
  font-weight: bold;
  font-size: 2rem;

  &:hover {
    box-shadow: 1px 0px 4px 1px orangered;
  }

  &__text {
    padding: 1rem;
  }
}

.friendshrimp-on-your-photo {
  position: absolute;
  width: 25%;
  max-width: 420px;
  bottom: 3rem;
  right: 2rem;
  z-index: 420;
  opacity: .8;
  box-shadow: 2px 1px -1px 1px #666;

}

.friendshrimp-photo-booth {
  position: relative;
  max-width: 100%;
  display: inline-block;

  &--photo {
    display: block;
    max-width: 100%;
    max-height: 100vh;
    height: auto;
    margin: 0 auto;
  }
}
