$water-color: #39C7E2;

.shrimps-in-water-container {
  position: relative;
  z-index: 10;
  min-height: 420px;
}

.shrimp-player {
  z-index: 11;

  &__name {
    font-weight: bold;
    font-size: 1.5rem;
    color: $water-color;
  }
}

.underwater {
  z-index: -1;
  height: 100%;
  background: radial-gradient(ellipse at center, rgba(255,254,234,1) 0%, rgba(255,254,234,1) 35%, #B7E8EB 100%);
  overflow: hidden;

  .waves {
    height: 5%;
    width:100%;
    position: absolute;
    bottom:0;
    left:0;
    background: #015871;
  }

  .wave {
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x; 
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    animation-delay: .25s;
    transform: translate3d(0, 0, 0);

    &:nth-of-type(2) {
      top: -175px;
      animation: swell 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
      animation-delay: .3s;
      opacity: 1;
    }
  }
}

@keyframes wave {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1600px, 0, 0);

    @media screen and (max-width: 767px) {
      transform: translate3d(-800px, 0, 0);
    }
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(-500px,5px,0);
  }
}
