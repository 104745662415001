.shrimp-cards-container {
  .button {
    background: blue;
    color: white;
    padding: 0.5rem 2rem;
    border-radius: 4.2rem;
    box-shadow: 0 0 6px #999;
    cursor: pointer;

    &--side-submit {
      border-radius: 0 4.2rem 4.2rem 0;
    }
  }

  .shrimp-cards__button {
    margin-bottom: 1rem;
    font-size: 1.8rem;
    max-width: 90%;
    color: white;
    font-family: 'shrimp_fontregular';
  }

  .pill {
    background: cyan;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0.5rem 2rem;
    border-radius: 4.2rem;
    box-shadow: 0 0 6px #333;
  }

  .main-input {
    font-size: 2rem;
  }

  .input-with-side-submit {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
  }

  .card-battle {
    padding-top: 1rem;

    &__displays {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__player-one,
    &__player-two {
      margin-bottom: 1rem;

      .shrimp-card {
        animation-name: fadeIn;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
