.draw-shrimps-container {
  width: 100%;
}

.draw-shrimps {
  &__input {
    padding: .4rem .25rem;
  }

  &__button {
    cursor: pointer;
    font-weight: bold;
    border: none;
    box-shadow: 0 0 6px #666;
    border-radius: 0 6px 6px 0;
    padding: .4rem 1.5rem;
    background-color: #FBC4BD;

    &--clear {
      padding: .4rem 1.25rem;
      font-weight: normal;
      border-radius: 3px;
      box-shadow: 0 0 3px #dedede;
      background-color: #efefef;
    }
  }
}

.shrimp-drawings {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  height: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
