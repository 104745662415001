.shrimp-divider {
  background: white;
  padding: 1rem;
  box-shadow: 3px 3px 3px rgba(69,69,69, .5);
  border-radius: 6px;
  grid-template-columns: 1fr 1fr;
  width: 1200px;
  max-width: calc(100% - 4rem);
  margin: 0 auto;

  .description {
    font-size: 14px;
  }

  @media screen and (max-width: 720px) {
    padding: .5rem;
    max-width: calc(100% - 2rem);
  }
}

.hit-status {
  color: #3d000f;
  font-size: 1.5rem;
  font-weight: bold;
}

.shrimps-container {
  display: flex;
  flex-wrap: wrap;
  width: 500px;
  max-width: calc(100% - 2rem);
  margin: 0 auto;
  padding: 1rem 0;

  .shrimp {
    svg {
      transition: all .75s;
    }

    &:hover {
      svg {
        transform: scale(1.3) rotate(-360deg);
      }
    }
  }
}

.remaining-juice {
  padding: 1rem;

  .juice-bar {
    width: 420px;
    max-width: calc(100% - 2rem);
    height: auto;
    font-weight: bold;
    font-size: 1.25rem;
    background-color: #333;
    margin: 0 auto;
    border: 1px solid #696969;
    border-radius: 3px;
    position: relative;
    padding: .5rem 1rem;
    transition: all 2s;
  }
}

.buttons-container {
  .button {
    font-size: 1.25rem;
    border-radius: 3px;
    background: #d83aff;
    border: 2px solid black;
    color: black;
    cursor: pointer;
    padding: .5rem 1.5rem;
    transition: all .5s;

    &:hover {
      background: pink;
    }

    &.reset {
      background-color: #e00000;
      color: white;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: gray;
      border-color: #ccc;
      font-style: italic;
    }
  }
}

.record-container {
  font-size: 1rem;
  margin-bottom: 2rem;

  &.new-record {
    color: green;
    animation-name: pulsate;
    animation-iteration-count: infinite;
    animation-duration: 2s;
    animation-direction: alternate;
  }
}

@keyframes pulsate {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.5);
  }
}
